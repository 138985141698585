import React from "react";
import AnimationWrapper from "../components/AnimationWrapper";
import Menu from "../components/Menu";
import SEO from "../components/SEO";

export default function coop() {
  return (
    <AnimationWrapper>
      <SEO />
      <div id="c-background">
        <Menu />
        <div className="subp-main flow c-adjustments">
          <h1 className="subp-animate">EGYÜTTMŰKÖDÉS</h1>
          <div className="content flow">
            <p className="paragraph-main subp-animate">
              Az Alapítvány a térség életében jelentős közösségépítő és megtartó
              hatású tevékenységet végez, mellyel a közösségi élethez
              elengedhetetlen értékeket közvetít. Működésének eredményeként
              érezhetően javult az elmúlt években is az együttműködési készség,
              mind a szervezetek, mind pedig a lakosság körében emelkedett a
              cselekvési kedv, kialakult a „valahová tartozás” élménye.
            </p>

            <p className="subp-animate">
              Az együttműködés során az önkéntesek bevonásával hozzájárulunk a
              programok sikeres megvalósulásához. Aktív részvétellel segítjük a
              hátrányos helyzetű csoportok bevonását, az őket támogató
              programokba. Mindezen célok elérése érdekében igyekszünk ellátni a
              ránk váró feladatokat a lehető legmagasabb színvonalon.
              Alapítványunk folyamatos és sikeres működésének záloga a
              nyitottság, a jó partneri kapcsolatok kialakítása és ápolása.
            </p>

            <ul className="subp-animate">
              <h3 className="subp-animate">Határon túli kapcsolatok</h3>
              <li className="subp-animate">
                Nagykárolyi Down Alapítvány – Nagykároly - Románia
              </li>
              <li className="subp-animate">
                Marosvécsi Neuropszichiátriai és Rehabilitációs Központ -
                Marosvécs - Románia
              </li>
              <li className="subp-animate">
                Gyermekek és Felnőttek Szociális Otthona - Ekecs-Apácaszakállas
                – Szlovákia
              </li>
            </ul>

            <ul className="subp-animate">
              <h4 className="subp-animate">
                Közösségépítő eseményeinkhez (sport- és egészségnap, művészeti
                bemutatókhoz és képzőművészeti kiállításhoz kapcsolódó
                fesztivál) együttműködő partnereink
              </h4>

              <li className="subp-animate">
                Napfényes Támogató Szociális Egyesület - Püspökladány
              </li>
              <li className="subp-animate">
                Kitáruló Világ Egyesület – Püspökladány
              </li>
            </ul>
          </div>
        </div>
      </div>
    </AnimationWrapper>
  );
}
